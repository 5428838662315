import { type SwhConfigStore } from 'swh/shared/components'

import { acquirerProfileMenu } from '~/domains/acquirer/ports/acquirer.menu'
import { miscellaneousProfileMenu } from '~/domains/miscellaneous/ports/miscellaneous.menu'
import { stoneAccountProfileMenu } from '~/domains/stone-account/ports/stone-account.menu'

import { type Entity } from '../../infra/deus-ex-machina/ports'
import { sortMenuByPriority } from '../shared/helpers'

export function profileMenuConfig(entity: Entity, swhStoreConfig?: SwhConfigStore) {
  return sortMenuByPriority([
    ...stoneAccountProfileMenu(),
    ...miscellaneousProfileMenu(swhStoreConfig),
    ...acquirerProfileMenu(entity)
  ])
}
