import { useEffect, useState } from 'react'
import { type DropdownSelectionOption } from '@stone-payments/jade'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Categories } from 'swh/shared/http'

export type Category = {
  subcategories?: DropdownSelectionOption[]
} & DropdownSelectionOption

export const useCategoriesOptions = (
  storeId: string | undefined,
  selectedCategory: string[] | undefined = undefined
) => {
  const [categoriesOptions, setCategoriesOptions] = useState<Category[]>([])

  const fetchCategories = async (id: string) => {
    const categories = await Categories.get(id)
    const categoriesAsOptions = categories.map(category => ({
      label: category.name,
      value: category.id.toString(),
      selected: selectedCategory?.includes(category.id.toString()),
      subcategories:
        category.subcategories?.map(subcategory => ({
          label: subcategory.name,
          value: subcategory.id.toString()
        })) || []
    }))
    setCategoriesOptions(categoriesAsOptions)
  }

  useEffect(() => {
    if (categoriesOptions.length === 0 || selectedCategory === undefined) return
    setCategoriesOptions(prev => prev.map(x => ({ ...x, selected: selectedCategory.includes(x.value) })))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  useEffect(() => {
    if (storeId) fetchCategories(storeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId])

  return categoriesOptions
}
