import { type PropsWithChildren, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useToggle, useWindowSize } from 'react-use'
// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useExperiment } from 'platform/lib/experiment'

import { ProfileMenu as NewProfileMenu } from '~/domains/home-v2/shell/sidebar/components/profile-menu.component'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import { ProfileMenu } from '~/domains/platform/layout/profile-menu/profile-menu'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { SettingsMenu } from '~/ui/business-components/settings/settings-menu'
import { CustomSidebar } from '~/ui/components/layouts/custom-sidebar'
import { SidebarLayout } from '~/ui/components/layouts/sidebar-layout/sidebar-layout'
import { TopBarLayout } from '~/ui/components/layouts/top-bar-layout'
import { GenericErrorMessage } from '~/ui/components/utils/generic-error-message/generic-error-message'
import { theme } from '~/ui/theme'

export interface SettingsNavigationProps {
  subject: Subject<'stone_account'>
  entity: Entity
  fullscreen?: boolean
}

export function SettingsNavigation(props: PropsWithChildren<SettingsNavigationProps>) {
  const { subject, children, entity, fullscreen = false } = props

  const { width } = useWindowSize()

  const [open, toggleOpen] = useToggle(false)

  const { isFeatureFlagEnabled, isLoading } = useExperiment(subject.id, 'flag-new-home-web')

  useEffect(() => {
    if (width >= theme.windowSizes.medium) {
      toggleOpen(true)
    }
  }, [toggleOpen, width])

  return (
    <TopBarLayout
      topBarContent={<SettingsMenu subject={subject} />}
      asideContent={<CustomSidebar open={open} toggleOpen={toggleOpen} />}
    >
      <SidebarLayout
        open={open}
        aside={
          !isLoading && (
            <Choose>
              <Choose.When condition={isFeatureFlagEnabled}>
                <NewProfileMenu entity={entity} />
              </Choose.When>
              <Choose.Otherwise>
                <ProfileMenu entity={entity} />
              </Choose.Otherwise>
            </Choose>
          )
        }
        fullscreen={fullscreen}
      >
        <ErrorBoundary
          FallbackComponent={() => <GenericErrorMessage />}
          onError={error => {
            logger(error)
          }}
        >
          {children}
        </ErrorBoundary>
      </SidebarLayout>
    </TopBarLayout>
  )
}
