/* eslint-disable testing-library/no-await-sync-query  */
/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect } from 'react'
import { type NextPage } from 'next'
import { Spinner } from '@stone-payments/jade'

import { useDeusExMachina } from '~/domains/platform/infra/deus-ex-machina/hocs/with-deus-ex-machina'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird from '~/domains/platform/lib/blackbird'

import { fetchStoreSwh } from './hooks/use-swh-config-store'
import { useSwhConfigContext } from './swh-config-context'
import { type SwhPageConfig } from './with-swh-config'

type WithSwhConfigProps = {
  Page: NextPage
  pageConfig?: SwhPageConfig
  [name: string]: any
}

const WithSwhConfig = ({ Page, pageConfig, ...props }: WithSwhConfigProps) => {
  const { deusExMachina } = useDeusExMachina()
  const [config, setConfig] = useSwhConfigContext()
  const entity = deusExMachina?.entity as Entity<'acquirer'>
  const subject = deusExMachina?.subject as Subject

  useEffect(() => {
    const fetchStoreConfig = async () => {
      if (!entity?.document) return

      const storeConfig = await fetchStoreSwh(entity)
      setConfig(storeConfig)
    }

    fetchStoreConfig()
  }, [entity, setConfig, subject?.id])

  if (entity?.document === undefined || config?.store?.id === undefined) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Spinner color="brand" size="xlarge" />
      </div>
    )
  }

  if (!pageConfig?.redirect404IfNotSwh === false && config?.store?.id === '') {
    BlackBird.travelTo('/404')
    return null
  }

  return <Page {...props} />
}

export function withSwhStoreConfig<P extends NextPage<any, any>>(Page: P, pageConfig?: SwhPageConfig) {
  return (props: any) => <WithSwhConfig {...props} Page={Page} pageConfig={pageConfig} />
}
