/* eslint-disable @nx/enforce-module-boundaries */
import { useQuery } from '@tanstack/react-query'
import { Stores } from 'swh/shared/http'

import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

import { type SwhConfigStore } from '../swh-config-context'

enum SwhEnabledModules {
  NFCE = 'nfce',
  CATALOG = 'catalog'
}

export const hasSomething = (item: SwhEnabledModules) => (configStore: SwhConfigStore | undefined) =>
  configStore?.store?.enabledModules?.includes(item) ?? false

export const hasSwhNfce = hasSomething(SwhEnabledModules.NFCE) ?? false
export const hasSwhCatalog = hasSomething(SwhEnabledModules.CATALOG) ?? false

export function useSwhConfigClient(entity: Entity | Entity<'acquirer'> | undefined) {
  return useQuery(['swhConfigStoreClient', entity?.document], () => entity && fetchStoreSwh(entity), {
    refetchOnWindowFocus: false,
    enabled: entity !== undefined
  })
}

export const fetchStoreSwh = async (entity: Entity | Entity<'acquirer'>): Promise<SwhConfigStore> => {
  // eslint-disable-next-line testing-library/no-await-sync-query
  const store = await Stores.getByDocument(entity.document)

  return {
    store: store ?? ({} as Stores.Item),
    merchant: { document: entity.document },
    affiliationKey: (entity as Entity<'acquirer'>).stoneCodes?.[0]?.affiliationKey ?? null
  }
}
