import { Heimdall } from '~/domains/platform/core/heimdall'
import { NightOwl } from '~/domains/platform/core/night-owl/night-owl'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

import analitica from './analitica'

export function acquirerProfileMenu(entity: Entity) {
  const isResourceOwner = NightOwl.verifyScopes(['acquireraffiliation:transactionalkey:write'])

  return [
    {
      order: 9,
      label: 'Contas de recebimento',
      icon: 'bank-outline',
      show: entity?.roles?.acquirer,
      onClick: () => {
        analitica.events.profileMenu.click('contas de recebimento')
      },
      path: '/contas-de-recebimento',
      active: (route: string) => ['/settings/receivable-accounts', '/contas-de-recebimento'].includes(route)
    },
    {
      order: 6,
      label: 'Meu plano',
      icon: 'notebook-outline',
      show: true,
      path: '/meu-plano',
      active: (route: string) => ['/settings/my-plan', '/meu-plano'].includes(route)
    },
    {
      order: 5,
      label: 'Chaves de autenticação',
      icon: 'key-outline',
      show: entity?.roles?.acquirer && Heimdall.pass(['stone_sak_keys_web']) && isResourceOwner,
      path: '/chaves-de-autenticacao',
      active: (route: string) => ['/settings/sak-keys', '/chaves-de-autenticacao'].includes(route)
    }
  ]
}
