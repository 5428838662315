/* eslint-disable @nx/enforce-module-boundaries */
import { Tag } from '@stone-payments/jade'
import { Invoices } from 'swh/shared/http'
import { InvoiceStatusColors, InvoiceStatusLabels } from 'swh/shared/utils'

type InvoiceStatusTagProps = {
  status: Invoices.Status
}

export const InvoiceStatusTag = ({ status }: InvoiceStatusTagProps) => {
  const invoiceStatus = Object.values(Invoices.Status).includes(status) ? status : Invoices.Status.Default
  const color = InvoiceStatusColors[invoiceStatus]
  const label = InvoiceStatusLabels[invoiceStatus]

  return (
    <Tag variant={color} size="medium">
      {label}
    </Tag>
  )
}
