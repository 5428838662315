import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { type IconId, Badge, Button, Icon, Text, theme } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'

import { analytics } from '../../lib/analytics'
import { NavLink, NavMenu } from '../shared/styles'

import { profileMenuConfig } from './menu-config'

export function ProfileMenu({ entity }: { entity: Entity }) {
  const { route } = useRouter()
  const query = BlackBird.getQuery()
  const profileMenuItems = profileMenuConfig(entity)

  const handleBack = () => {
    BlackBird.back()
    analytics.sendEvent('home web - menu perfil chosen button', { 'menu-perfil': 'botao-voltar' })
  }

  return (
    <Box>
      <Box m="0.75rem">
        <Flex alignItems="center">
          <Button onClick={handleBack} icon="direction-arrow-left-outline" color="neutral" variant="contentOnly">
            Voltar
          </Button>
        </Flex>
      </Box>
      <Divider backgroundColor={theme.light.color.borderLow} />
      <Box m="0.75rem">
        <NavMenu>
          <For
            //@ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
            of={profileMenuItems}
            render={(item, index) => {
              const { label, icon, show, onClick, path, active, order, isNew } = item
              return (
                <If key={order} condition={Boolean(show)}>
                  <NextLink href={{ pathname: path, query }} passHref legacyBehavior>
                    <NavLink key={index} current={Boolean(active && active(route))} onClick={onClick}>
                      <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Flex alignItems="center" gap="0.5rem">
                          <Icon use={icon as IconId} color="neutral" />
                          <Text inheritColor weight="semi">
                            {label}
                          </Text>
                        </Flex>
                        {isNew ? (
                          <Badge variant="solid" color="info" shape="pill">
                            Novo!
                          </Badge>
                        ) : null}
                      </Flex>
                    </NavLink>
                  </NextLink>
                </If>
              )
            }}
          />
        </NavMenu>
      </Box>
    </Box>
  )
}
